import React from "react";
import { css, jsx } from "@emotion/react";
import { SectionStyle } from "../../styles/common";

import arrowRight from "../../images/arrow-right-in-circle.svg";

const ViewTimetableStyle = css`
  ${SectionStyle}
  padding: 120px 0;

  .view-timetable {
    display: block;
    text-decoration: none;
    color: inherit;

    text-align: center;;
  }

  .view-timetable__title {
    margin-top: 0;
    margin-bottom: 30px;

    font-size: 32px;
    font-weight: 700;
    line-height: 38px;
  }

  .view-timetable__title br {
    display: none;
  }

  .view-timetable__image {
    width: 64px;
    height: 64px;
  }

  @media (max-width: 400px) {
    .view-timetable__title {
      font-size: 24px;
      line-height: 34px;
    }

    .view-timetable__title br {
      display: initial;
    }
  }

`;

export const SessionsPageViewTimetableSection: React.FC = (props) => {

  return (
    <section css={ViewTimetableStyle}>
      <a href="/contents#timetable" className="view-timetable container">
        <h3 className="view-timetable__title">엑셀콘의 <span className="text-secondary">전체 시간표</span>를 <br />확인하세요</h3>
        <img className="view-timetable__image" src={arrowRight} alt="Right Arrow" />
      </a>
    </section>
  );
}
