import React from "react";
import imgHeroBackground from "../images/hero_bg.jpg";
import excelconLogoWhite from "../images/excelcon_logo_1-2.png";
import { css } from "@emotion/react";

const PageHeroStyle = css`
  background-image: url(${imgHeroBackground});
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  padding-top: 4.5rem;
  height: 40rem;

  .page__hero-content {
    width: 100%;
  }

  .page__hero-content > img {
    width: 271px;
    max-width: 60%;
  }

  .page__hero-content > h1 {
    font-size: 3rem;
    margin-top: 70px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    height: 30rem;

    .page__hero-content > h1 {
      font-size: 2.5rem;
    }
  }
`;

export const PageHero = ({ children }) => {
  return <div className="page__hero" css={PageHeroStyle}>
    <div className="page__hero-content container">
      <img src={excelconLogoWhite} alt="Excelcon" />
      {children}
    </div>
  </div>;
}