import React from "react";
import { css, jsx } from "@emotion/react";
import { SectionStyle } from "../../styles/common";

import imgEvent1 from "../../images/contents/event-1.jpg";
import imgEvent2 from "../../images/contents/event-2.jpg";

const EventSectionStyle = css`
  ${SectionStyle}
  padding: 120px 0;
  background-color: #171717;

  h2 {
    margin: 0 0 50px;

    font-size: 40px;
    line-height: 47.73px;
    font-weight: 700;
  }

  .events {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
  }

  .event {
    border-radius: 8px;
    overflow: hidden;

    display: flex;
    flex-direction: column;
    align-items: center;

    background-color: #E6E6E6;
    color: black;
  }

  .event img {
    width: 100%;
    object-fit: cover;
  }

  .event__content {
    padding: 30px 40px;
  }

  .event__content-title {
    margin: 0 0 10px;
    text-align: center;

    font-size: 24px;
    font-weight: 700;
    line-height: 29px;
  }

  .event__content-description {
    margin: 0;

    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: -0.02em;
  }


  @media (max-width: 768px) {
    h2 {
      margin-bottom: 20px;

      font-size: 24px;
      line-height: 29px;
      font-weight: 700;
      letter-spacing: -0.01em;
    }

    .events {
      grid-template-columns: 1fr;
    }

    .event img {
      max-height: 160px;
    }

    .event__content {
      padding: 20px 16px;
    }

    .event__content-title {
      margin: 0 0 14px;
      text-align: center;

      font-size: 20px;
      line-height: 24px;
    }
    .event__content-description {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
    }

  }

`;



export const ContentsPageEventSection: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <section css={EventSectionStyle}>
      <div className="container">
        <h2>이벤트</h2>
        <div className="events">
          <div className="event">
            <img src={imgEvent1} alt="event-1" />
            <div className="event__content">
              <h3 className="event__content-title text-secondary">럭키드로우</h3>
              <p className="event__content-description"><strong>Glenfiddich Grand Cru, Glenfiddich 18 Year Old</strong> 등 고급 위스키와 엑셀플레이스 선불카드가 걸린 럭키 드로우! 당첨의 주인공은 누가 될까요?!</p>
            </div>
          </div>
          <div className="event">
            <img src={imgEvent2} alt="event-2" />
            <div className="event__content">
              <h3 className="event__content-title text-secondary">네트워킹</h3>
              <p className="event__content-description">엑셀콘에는 다양하고 멋진 개발자분들이 참석합니다. 함께 게임을 하고 얘기를 나누며 친해져보아요!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
