import React, { useCallback, useEffect, useState } from "react"
import { css, jsx } from "@emotion/react"
import imgHeroBackground from "../../images/hero_bg.jpg"
import imgExcelconLogoWhite from "../../images/excelcon_logo_1-2.png"
import imgHeroLogo from "../../images/hero_logo.png"
import { LoginPopup } from "../../components/LoginPopup"
import { MyTicketPopup } from "../../components/MyTicketPopup"
import { useRecoilState } from "recoil"
import { accessTokenState } from "../../accessTokenState"
import { NotSelectedPopup } from "../../components/NotSelectedPopup"

const HeroSectionStyle = css`
  background: url(${imgHeroBackground}) center;
  background-size: cover;
  min-height: 90vh;
  position: relative;
  text-align: center;

  padding-top: 9rem;
  padding-bottom: 4.5rem;

  display: flex;
  align-items: center;

  .container {
    position: relative;
    z-index: 1;
    flex-grow: 1;
  }


  h1 {
    margin: 0 0 2.5rem 0;

    img {
      max-width: 390px;
      width: 75%;
    }
  }

  h2 {
    margin-bottom: 2.5rem;
  }

  .catch-phrase {
    font-family: 'GmarketSans';
    font-size: 2.5rem;
    font-weight: 700;
    letter-spacing: -2px;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }

  .event-description {
    font-size: 1.333rem;
    font-weight: 500;
  }

  .price-notice {
    text-align: center;
    font-size: .75rem;
    margin-top: .5rem;
  }
`;

const InquiryButtonStyle = css`
  display: inline-block;
  margin-top: 1.5rem;
  padding: 1rem 2rem;
  border-radius: 1.5rem;
  border: none;
  background: var(--color-primary);
  font-size: 16px;
  line-height: 1;
  text-decoration: none;
  color: white;

  &::visited {
    color: inherit;
    text-decoration: none;
  }
`;

export function HeroSection() {
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showMyTicketPopup, setShowMyTicketPopup] = useState(false);
  const [showNotSelectedPopup, setShowNotSelectedPopup] = useState(false);

  const [ accessToken, setAccessToken ] = useRecoilState(accessTokenState);

  const onLogin = useCallback((token) => {
    setAccessToken(token);
    setShowMyTicketPopup(true);

    window.localStorage.setItem('excelcon-token', token);
  }, []);

  return <>
  <NotSelectedPopup visible={showNotSelectedPopup} onClose={() => setShowNotSelectedPopup(false)} />
  <LoginPopup visible={showLoginPopup} onClose={() => setShowLoginPopup(false)} onLogin={onLogin} onNotSelected={() => setShowNotSelectedPopup(true)}/>
    <MyTicketPopup visible={showMyTicketPopup} onClose={() => setShowMyTicketPopup(false)} accessToken={accessToken} />
    <section css={HeroSectionStyle}>
      <div className="container">
        <h1><img src={imgHeroLogo} alt="1st Excelcon" /></h1>
        <p className="catch-phrase">
          코딩이 술~술~ 풀리는<br />
          네트워킹 파티 <span className="text-secondary">EXCELCON</span>
        </p>
        <p className="event-description">2022.09.17. 마장동 EXCEL PLACE</p>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div>
            {
              accessToken
                ? (<button css={InquiryButtonStyle} onClick={() => setShowMyTicketPopup(true)}>내 티켓 보기</button>)
                : (<button css={InquiryButtonStyle} onClick={() => setShowLoginPopup(true)}>당첨 확인하기</button>)
            }
          </div>
        </div>
      </div>
    </section>
  </>
}