import React, { useEffect } from "react"
import { css, jsx } from "@emotion/react"
import { GlobalNavigationBar } from "../../components/GlobalNavigationBar"
import SiteHead from "../../head";
import { ContentsPageTimetableSection } from "../../contentsPage/sections/contentsPageTimetableSection";
import { ContentsPageFoodSection } from "../../contentsPage/sections/contentsPageFoodSection";
import { ContentsPageEventSection } from "../../contentsPage/sections/contentsPageEventSection";
import { PageHero } from "../../components/PageHero";
import { RecoilRoot } from "recoil";
import { ContentsPageHoodieSection } from "../../contentsPage/sections/contentsPageHoodieSection";

const PageStyle = css`
  padding-bottom: 10rem;
  background: #171717;
`;

export const Head = () => <SiteHead meta={{ title: "행사 즐기기 | EXCELCON" }} />

const ContentsPage = () => {

  const timetableRef = React.createRef();
  useEffect(() => {
    if (window.location.hash.includes("timetable") && timetableRef.current) {
      timetableRef.current?.scrollIntoView();
    }
  }, [timetableRef])

  return <RecoilRoot>
    <div css={PageStyle}>
      <GlobalNavigationBar />
      <PageHero>
        <h1>다양한 사람들과 함께<br /><span className="text-secondary">맛있는 음식</span>과 <span className="text-secondary">네트워킹</span>을 즐겨요</h1>
      </PageHero>
      <ContentsPageHoodieSection />
      <ContentsPageFoodSection />
      <ContentsPageTimetableSection timetableRef={timetableRef} />
      <ContentsPageEventSection />
    </div>
  </RecoilRoot>
}

export default ContentsPage
