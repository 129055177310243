import React from "react";

import sessionImage1 from "../images/sessions/1.jpg";
import sessionImage2 from "../images/sessions/2.jpg";
import sessionImage3 from "../images/sessions/3.jpg";
import sessionImage4 from "../images/sessions/4.jpg";
import sessionImage5 from "../images/sessions/5.jpg";
import sessionImage6 from "../images/sessions/6.jpg";
import sessionImage7 from "../images/sessions/7.jpg";

import sessionDetailImage1 from "../images/sessions/detail-1.jpg";
import sessionDetailImage2 from "../images/sessions/detail-2.jpg";
import sessionDetailImage3 from "../images/sessions/detail-3.jpg";
import sessionDetailImage4 from "../images/sessions/detail-4.jpg";
import sessionDetailImage5 from "../images/sessions/detail-5.jpg";
import sessionDetailImage6 from "../images/sessions/detail-6.jpg";
import sessionDetailImage7 from "../images/sessions/detail-7.jpg";

export interface SessionData {
  slug: string;
  profileImageUrl: string;
  detailPageImageUrl: string;
  description: string;
  duration: string;
  time: string;
  presenter: {
    socialMediaAccount: string;
    name: string;
    description: string;
  };
  title: string;
  decoratedTitle: JSX.Element;
}

// description: "",

export const sessionDataFirstFloor: SessionData[] = [
  {
    slug: "1",
    title: "3회차 독학러의 프론트엔드 삽질기",
    decoratedTitle: <>3회차 독학러의 <span className="text-secondary">프론트엔드 삽질기</span></>,
    profileImageUrl: sessionImage1,
    detailPageImageUrl: sessionDetailImage1,
    duration: "30",
    time: "18:30 ~ 19:00",
    description: "이전에 독학했던 경험을 바탕으로 학원을 가지 않고도 어떻게 공부했는지, 어떤점이 어려웠는지, 그리고 이렇게 했다면 더 좋았을걸! 하는 아쉬움을 이야기 합니다.",
    presenter: {
      socialMediaAccount: "goldbear2022",
      name: "금곰",
      description: "1회차에는 그림(크로키)를, 2회차에는 퍼블리싱을 독학했고 현재는 프론트엔드를 공부하고 있는 취준생입니다.",
    },
  },
  {
    slug: "2",
    title: "Hello, Interactive Developer",
    decoratedTitle: <>Hello, <span className="text-secondary">Interactive Developer</span></>,
    profileImageUrl: sessionImage2,
    detailPageImageUrl: sessionDetailImage2,
    duration: "30",
    time: "19:10 ~ 19:40",
    description: `보여지는 작업이 좋아 프론트엔드 개발자가 된 여러분들
혹은 프론트엔드 개발자는 아니지만 '예술'을 좋아하는 여러분들

함께 웹에서 '아름다움을 코딩'해보는 건 어떨까요?

게임 개발 분야 중에는 화려한 이펙트를 담당하는 테크니컬 아티스트(TA)라는 직군이 있습니다.
웹에서는 어떨까요?

화면을 만드는 프론트엔드 개발자가 웹에서 아트를 한다면,
여기에 인터랙션까지 더해진다면, <인터랙티브 디벨로퍼>가 될 수 있습니다.

이 세션에서는 WebGL을 이용해 아트워크를 만드는 길을 소개해드립니다.`,
    presenter: {
      socialMediaAccount: "e_philo_",
      name: "이필로",
      description: `사람들이 숨쉬는 무대를 좋아합니다.
그래서 연극을 공부했습니다.

학교에서 인터랙티브 아트를 접했고
코딩을 배우기 시작했습니다.

지금은 콘텐츠 기업에서 아름다운 웹에 대해 연구하고 있습니다.
WebGL과 Shader 기술을 이용해 아트워크를 만드는 작업을 즐깁니다.

언젠가 많은 사람들이 놀러올 수 있는 웹 아트워크 전시를 만드는 게 꿈입니다.`,
    },
  },
];

export const sessionDataSecondFloor: SessionData[] = [
  {
    slug: "3",
    title: "첫 개발자로 입사해서 살아남기",
    decoratedTitle: <><span className="text-secondary">첫 개발자</span>로 입사해서 살아남기</>,
    profileImageUrl: sessionImage3,
    detailPageImageUrl: sessionDetailImage3,
    duration: "20",
    time: "18:30 ~ 18:50",
    description: `사수가 없는 환경에서 일하고 계시지는 않나요?

명확한 기획 없이 코드를 작성하고 계시지는 않나요?

회사에서 스스로 통제할 수 없는 영역 때문에 지치고 스트레스를 받게 됩니다. 저는 이런 상황 속에서도 자신의 성장과 팀의 성장을 포기하고 싶지 않았습니다.

첫 번째 개발자로 입사해서 하나의 서비스를 오픈하고 운영하며 겪었던 경험을 공유합니다.`,
    presenter: {
      socialMediaAccount: "wenodev",
      name: "손주원",
      description: `안녕하세요. 첫 번째 개발자로 입사해서 기술 역량과 함께 제품 관리자 역할을 담당했던 손주원 이라고 합니다.

좋은 코드와 좋은 개발자에 대해 고민하고 토론하는 것을 좋아합니다.`,
    },
  },
  {
    slug: "4",
    title: "신입 개발자가 야생의 이직 시장에서 살아가는 방식",
    decoratedTitle: <>신입 개발자가 <span className="text-secondary">야생의 이직 시장</span>에서 살아가는 방식</>,
    profileImageUrl: sessionImage4,
    detailPageImageUrl: sessionDetailImage4,
    duration: "20",
    time: "19:00 ~ 19:20",
    description: `돈만이 최고였던 신입 개발자의 두 차례 이직 과정을 알려드리려고 합니다.
이직하면서 깨닫게 된 점, 이직하게 된 사유, 면접 준비 과정, 마인드 컨트롤과 고민했던 부분에 대해 깊게 이야기합니다.`,
    presenter: {
      socialMediaAccount: "whuii133",
      name: "휘",
      description: "스타트업 백엔드 개발자입니다! 요즘 관심있는 것은 인테리어, 방탈출입니다:) 무언가를 기록하고 공유하는 것을 좋아합니다.",
    },
  },
  {
    slug: "5",
    title: "개발자로 창업하기 (3일만에 가성비 MVP 제작하는 법)",
    decoratedTitle: <><span className="text-secondary">개발자로 창업하기</span><br />(3일만에 가성비 MVP 제작하는 법)</>,
    profileImageUrl: sessionImage5,
    detailPageImageUrl: sessionDetailImage5,
    duration: "20",
    time: "19:30 ~ 19:50",
    description: `쉽고 빠르게 프로덕트(MVP)를 만들고 시장 검증하는 법, 개발자로 창업한 경험, 개발자 창업의 장단점 등을 공유합니다.
창업에 당장 관심없더라도 사이드프로젝트에 관심있으신 개발자 분들이라면 분명 재밌게 들으실 수 있을거예요 :)`,
    presenter: {
      socialMediaAccount: "csecr24",
      name: "크롱",
      description: `즐겁다! 항상 즐거운 크롱입니다.
주니어 웹 프론트엔드 개발자가 어쩌다 창업을 했고, 현재 스타트업을 운영 중입니다.
프로덕트를 만들고 사람들에게 가치를 전달하는 일은 언제나 즐겁습니다.`,
    },
  },
  {
    slug: "6",
    title: "크로스핏에서 배우는 데일리 성장 루틴",
    decoratedTitle: <>크로스핏에서 배우는 <span className="text-secondary">데일리 성장 루틴</span></>,
    profileImageUrl: sessionImage6,
    detailPageImageUrl: sessionDetailImage6,
    duration: "20",
    time: "20:00 ~ 20:20",
    description: `CrossFit은 어렵습니다. 처음 하는 사람들에게 있어서는 너무나도 복잡한 루틴을 가지고 있고, 또 진입장벽도 높아보이죠. 하지만 그 재미가 생기면 굉장히 큰 엔돌핀을 얻게 되는데, 저는 이게 개발과도 비슷하다고 생각했습니다.

CrossFit의 철학과 루틴, 그리고 그 과정에서 어떻게 성장을 이룰 수 있는가를 토대로 개발자의 성장 루틴을 이야기해보려고 합니다.`,
    presenter: {
      socialMediaAccount: "euncho__",
      name: "조은",
      description: "Twitter에서 CrossFit을 영업하고 있는 FE Lead입니다. '혹시 그 조은님이세요?' 할 때 그 조은님입니다.",
    },
  },
  {
    slug: "7",
    title: "도전과 성장에 몰입할 수 있는 개발자의 마음 만들기",
    decoratedTitle: <><span className="text-secondary">도전과 성장</span>에 몰입할 수 있는 개발자의 마음 만들기</>,
    profileImageUrl: sessionImage7,
    detailPageImageUrl: sessionDetailImage7,
    duration: "20",
    time: "20:30 ~ 20:50",
    description: `해박한 프로그래밍 지식도, 뛰어난 문제해결능력도 없는 비전공자 신입 개발자가 어떻게 일 년동안 팀의 유일한 프론트엔드 개발자로 일할 수 있었을까요?
주니어 개발자들에게 배워야할 기술, 알아둬야할 지식들은 너무나 많습니다. 하지만 어떤 마음을 먹을 때 더 몰입해서 받아들이고 성장할 수 있는 지에 대해서는 뚜렷한 정답이 없습니다. 아마 모든 사람들의 삶이 다르고, 경험이 다르기때문일 것입니다.
그럼에도 불구하고, 저의 경험을 바탕으로 ‘일하는 나’와 ‘내가 일하고 있는 환경’를 바르게 이해하는 관점을 가졌을 때, 그 효과가 어떠했는 지와 더불어, 좀 더 단단하고 자신감 있는 주니어 개발자가 되기까지 꾸준히 실천한 원칙들에 대해 이야기해보고자 합니다.`,
    presenter: {
      socialMediaAccount: "a_lady_on_fire",
      name: "뽐므",
      description: `스노우, 샌드박스네트워크 등에서 스트리밍 서비스 운영, 이스포츠 마케팅 등 하고 싶은 일만 하다가, 하고 싶은 것을 더 오래 하기위해서 개발을 시작하게 된 주니어 프론트엔드 개발자입니다. 개발자로 커리어를 전환 후, 프로덕트에 대한 애정과 오너십을 가지고 사용자들에게 사랑받는 서비스를 만들고 있는 것에 즐거움과 자부심을 느끼고 있습니다.
요가와 명상으로 몸과 마음, 행동과 생각의 습관을 의식적으로 바라보고 변화시키는 것에 관심이 많고, 이를 커리어에도 적용하고자 노력합니다.`,
    },
  },
];
