import React from "react";
import { css, jsx } from "@emotion/react";
import { SectionStyle } from "../../styles/common";

import { SessionData, sessionDataFirstFloor, sessionDataSecondFloor } from "../sessions";

const SessionsSectionStyle = css`
  ${SectionStyle}
  padding-bottom: 0;

  .session-list {
    list-style: none;
    padding: 0;
  }

  .session-list-item {
    width: 100%;
    margin-bottom: 20px;

    a {
      text-decoration: none;
      height: 230px;
    }

    h4, p {
      margin: 0;
    }
  }

  .session-list-item:last-child {
    margin-bottom: 0;
  }

  .session-list-item--header {
    margin-top: 120px;
    margin-bottom: 30px;

    color: white;
    font-size: 32px;
    font-weight: 600;
  }

  .session {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;

    border-radius: 8px;
    overflow: hidden;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  }

  .session__content {
    padding: 30px 60px 20px 20px;
    background-color: white;
    color: black;

    display: inline-flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
  }

  .session__content-duration,
  .session__speaker {
    color: rgba(0, 0, 0, 0.7);
    font-size: 16px;
    line-height: 21.79px;
    font-weight: 500;
  }

  .session__title,
  .session__content-time {
    font-size: 24px;
    line-height: 32.69px;
    font-weight: 600;
  }

  .session__content-time {
    font-weight: 700;
    margin-top: 5px;
  }

  .session__speaker a {
    color: inherit;
  }

  .session__image {
    max-height: 100%;
    max-width: 296px;
    object-fit: cover;
  }

  @media (max-width: 680px) {

    .session-list {
      display: flex;
      flex-wrap: wrap;
      column-gap: 10px;
    }

    .session-list-item {
      width: calc(50% - 5px);
    }

    .session-list-item a {
      min-height: 294px;
    }

    .session-list-item--header {
      width: 100%;
      margin-top: 60px;
      margin-bottom: 15px;

      font-size: 16px;
      line-height: 19.09px;
    }

    .session {
      flex-direction: column;
    }

    .session__image {
      width: 100%;
      max-height: 120px;
    }

    .session__content {
      padding: 10px;
    }

    .session__content-bottom {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
      height: 100%;

      margin-top: 10px;
    }

    .session__content-duration,
    .session__speaker,
    .session__content-time {
      font-size: 12px;
      line-height: 16.34px;
    }

    .session__title {
      font-size: 16px;
      line-height: 21.79px;
    }

    .session__content-duration {
      display: inline-block;
      background-color: black;
      border-radius: 999px;
      padding: 3px 8px;

      color: white;
    }

    .session__content-time {
      display: inline-block;
      margin-left: 6px;
    }

  }

  /* 360px overflow fix */
  @media (max-width: 375px) {
    .session__content-time {
      font-size: 10px;
      margin-left: 4px;
    }
  }

`;

interface SessionsPageSessionsSectionProps {
}

interface SessionProps {
  session: SessionData
}


const Session: React.FC<SessionProps> = ({ session }) => {

  return <li className="session-list-item" key={session.slug}>
  <a href={`/sessions/${session.slug}`} className="session">
    <img
      src={session.profileImageUrl}
      className="session__image"
      alt="Session profille image"
    />
    <span className="session__content">
      <span>
        <div className="session__content-duration">{session.duration}분 세션</div>
        <div className="session__content-time">{session.time}</div>
      </span>
      <span className="session__content-bottom">
        <p className="session__speaker">
          {session.presenter.name} (@{session.presenter.socialMediaAccount})
        </p>
        <h4 className="session__title">
          {session.title}
        </h4>
      </span>
    </span>
  </a>
</li>
}

export const SessionsPageSessionsSection: React.FC<React.PropsWithChildren<SessionsPageSessionsSectionProps>> = (props) => {
  return (
    <section css={SessionsSectionStyle} className="sessions">
      <div className="container">
        <ul className="session-list">
          <li className="session-list-item session-list-item--header">1층 <span className="text-secondary">Beef Bar</span></li>
          {
            sessionDataFirstFloor.map(session => (<Session session={session} />))
          }
        </ul>
        <ul className="session-list">
          <li className="session-list-item session-list-item--header">2층 <span className="text-secondary">Whisky Library</span></li>
          {
            sessionDataSecondFloor.map(session => (<Session session={session} />))
          }
        </ul>
      </div>
    </section>
  );
}
