import React, { useEffect, useState } from "react"
import { css } from "@emotion/react";
import { SectionStyle } from "../../styles/common";

const MessageSectionStyle = css`
  ${SectionStyle};
  background: #1f1f1f;

  h2 {
    margin-bottom: 1rem;
  }

  h3 {
    margin-top: 1rem;
    margin-bottom: 4rem;
  }

  .message-container {
    display: flex;
    margin-left: -.5rem;
    margin-right: -.5rem;
  }

  .message-list {
    flex: 1 0 50%;
    padding-left: .5rem;
    padding-right: .5rem;

    list-style: none;

    li {
      display: flex;
      margin-bottom: 1rem;
    }

    .bubble {
      padding: 8px;
      max-width: 70%;
      line-height: 1.4;

      border-radius: .5rem .5rem .5rem 0;
      background: #751025;
      box-shadow: 0 .5rem .75rem 0 rgba(0,0,0,.1);
    }

    li:nth-child(2n) .bubble {
      margin-left: auto;
      border-radius: .5rem .5rem 0 .5rem;
      background: #844000;
    }
  }

  @media (max-width: 768px) {
    h3 {
      margin-bottom: 3rem;
    }

    .message-container {
      display: block;
    }
  }
`;


export function MessageSection() {
  return <section css={MessageSectionStyle}>
    <div className="container">
      <h2>
        엑셀콘은 많은 분들의<br />
        <span className="text-secondary">응원</span>으로 만들어집니다
      </h2>
      <h3>함께해주셔서 진심으로 감사합니다!</h3>
      <div style={{ overflow: 'hidden' }}>
        <div className="message-container">
          <ul className="message-list">
            <li>
              <div className="bubble">
                열어주셔서 너무 감사합니다~~~!!!!
              </div>
            </li>
            <li>
              <div className="bubble">
                정기적으로 진행되면 좋을 것 같아요~!
              </div>
            </li>
            <li>
              <div className="bubble">
                부디 엑셀콘이 이번 회차로 끝나지 않고 오래오래 많은 분들이 참가하고 교류할 수 있는 즐거운 개발자들의 파티가 되길 바랍니다! 화이팅입니다!
              </div>
            </li>
            <li>
              <div className="bubble">
                좋은 이벤트를 열어주셔서 감사합니다
              </div>
            </li>
            <li>
              <div className="bubble">
                우와우..!! 엑셀플레이스 말만듣고 아직 가보지못했는데 정말 행사가 열리다니 너무 재밌어요 기대돼요~~~ 😆😆
              </div>
            </li>
            <li>
              <div className="bubble">
                주최해주셔서 감사합니다
              </div>
            </li>
            <li>
              <div className="bubble">
                좋은 모임 주최해주셔서 감사합니다.
              </div>
            </li>
            <li>
              <div className="bubble">
                소심쟁이라… 소심쟁이도 자연스럽게 소개하고 녹아들 수 있는.. 그런.. 시간이 있으면 뭐든 좋읍니다..ㅎ
              </div>
            </li>
          </ul>
          <ul className="message-list">
            <li>
              <div className="bubble">
                좋은 자리 만들어주셔서 감사합니다. 꼭 참여하고싶어요!
              </div>
            </li>
            <li>
              <div className="bubble">
                우와우..!! 엑셀플레이스 말만듣고 아직 가보지못했는데 정말 행사가 열리다니 너무 재밌어요 기대돼요~~~ 😆😆
              </div>
            </li>
            <li>
              <div className="bubble">
                주최해주셔서 감사합니다
              </div>
            </li>
            <li>
              <div className="bubble">
                좋은 모임 주최해주셔서 감사합니다.
              </div>
            </li>
            <li>
              <div className="bubble">
                현업자와 취준생의 적극적이고 부드러운 교류가 있으면 좋겠어요!
              </div>
            </li>
            <li>
              <div className="bubble">
                좋은 네트워킹 행사 만들어주셔서 감사합니다!
              </div>
            </li>
            <li>
              <div className="bubble">
                좋은 자리 만들어주셔서 감사합니다. 꼭 참여하고싶어요!
              </div>
            </li>
            <li>
              <div className="bubble">
                좋은 네트워킹 행사 만들어주셔서 감사합니다!
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
}