import React from "react"
import { RecoilRoot } from "recoil"
import SiteHead from "../../head"

import { SessionPage as SessionPageComponent } from "../../sessionPage/sessionPage"
import { sessionDataSecondFloor } from "../../sessionsPage/sessions"

const SessionPage = () => {
  return <RecoilRoot><SessionPageComponent session={sessionDataSecondFloor[0]} floor="2층" /></RecoilRoot>
}

export const Head = () => <SiteHead meta={{ title: `${sessionDataSecondFloor[0].title} | EXCELCON` }} />

export default SessionPage
