import React, { useEffect, useState } from "react"
import { css } from "@emotion/react";
import { SectionStyle } from "../../styles/common";

const LocationSectionStyle = css`
  ${SectionStyle};
  background: 171717;
  color: white;

  h2 {
    margin-bottom: 4rem;
  }

  h3 {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 2rem;
    font-weight: 500;
  }

  .notice {
    margin-bottom: 4rem;
    font-size: 1rem;
    font-weight: 100;
  }

  .map-wrap {
    margin-left: auto;
    margin-right: auto;

    border-radius: 1rem;
    overflow: hidden;

    background: var(--color-primary);
  }

  .root_daum_roughmap {
    max-width: 100%;
  }
`;

export function LocationSection() {
  useEffect(() => {
    new window.daum.roughmap.Lander({
      "timestamp": "1661785492916",
      "key": "2bivo",
      "mapWidth": "928",
      "mapHeight": "400"
    }).render();
  }, []);

  return <>
    <section css={LocationSectionStyle}>
      <div className="container">
        <h2>
          <span className="text-secondary">마장동 엑셀플레이스</span>에서<br />
          9월 17일에 만나요!
        </h2>
        <h3>
          서울시 성동구 고산자로 335
        </h3>
        <p className="notice">
          별도의 주차 공간은 제공 되지 않습니다.
        </p>
        <div className="map-wrap">
          <div id="daumRoughmapContainer1661785492916" className="root_daum_roughmap root_daum_roughmap_landing"></div>
        </div>
      </div>
    </section>
  </>
}