import React from "react"
import { css, jsx } from "@emotion/react"
import { SectionStyle } from "../../styles/common";
import imgSpeaker1 from "../../images/speakers/1_sm.png";
import imgSpeaker2 from "../../images/speakers/2_sm.png";
import imgSpeaker3 from "../../images/speakers/3_sm.png";
import imgSpeaker4 from "../../images/speakers/4_sm.png";
import imgSpeaker5 from "../../images/speakers/5_sm.png";
import imgSpeaker6 from "../../images/speakers/6_sm.png";
import imgSpeaker7 from "../../images/speakers/7_sm.png";
import { sessionDataFirstFloor, sessionDataSecondFloor } from "../../sessionsPage/sessions";
import arrowRightCircle from "../../images/arrow_right_circle.png";

const speakerImages = {
  "1": imgSpeaker1,
  "2": imgSpeaker2,
  "3": imgSpeaker3,
  "4": imgSpeaker4,
  "5": imgSpeaker5,
  "6": imgSpeaker6,
  "7": imgSpeaker7,
}

const SessionsSectionStyle = css`
  ${SectionStyle}
  background: #0c0c0c;

  .session-list {
    display: flex;
    flex-wrap: wrap;

    list-style: none;
    padding: 0;
    margin-left: -.5rem;
    margin-right: -.5rem;

    .session-list-item {
      width: calc(50% - 1rem);
      margin-left: .5rem;
      margin-right: .5rem;
      margin-bottom: 1rem;

      position: relative;

      @media (max-width: 768px) {
        width: 100%;
      }

      > a {
        height: 240px;
        display: block;
        color: inherit;
        text-decoration: none;

        padding: 1.25rem;

        background: #404040;
        border-radius: .5rem;

        @media (max-width: 768px) {
          height: 210px;
        }
      }

      h4 {
        margin: 0 0 1.25rem 0;
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1.4;

        @media (max-width: 480px) {
          font-size: 1.5rem;
        }
      }

      p {
        margin: 0;
        font-size: 1.25rem;

        @media (max-width: 768px) {
          font-size: 1rem;
        }

        a {
          color: inherit;
          text-underline-offset: 2px;
          text-decoration-thickness: 1px;
        }
      }

      img {
        position: absolute;
        right: 1.25rem;
        bottom: 1.25rem;
        border-radius: 50px;

        @media (max-width: 768px) {
          width: 75px;
          height: 75px;
        }
      }
    }

    .sessions-notice {
      width: calc(50% - 1rem);
      height: 240px;
      margin-left: .5rem;
      margin-right: .5rem;
      margin-bottom: 1rem;
      padding: 1.25rem;

      background: #232323;
      border-radius: .5rem;
      position: relative;

      @media (max-width: 768px) {
        width: 100%;
        height: auto;
      }

      h4 {
        margin: 0 0 1.25rem 0;
        font-size: 1.75rem;
        font-weight: 700;
        line-height: 1.4;

        @media (max-width: 480px) {
          font-size: 1.5rem;
        }
      }

      p {
        margin: 0;
        line-height: 1.4;
        font-size: 1.2rem;

        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }

      .sessions-notice-button {
        position: absolute;
        right: 1.875rem;
        bottom: 1.875rem;

        img {
          display: block;
        }

        @media (max-width: 768px) {
          margin-top: .5rem;
          display: block;
          position: static;
          width: 2rem;
          height: 2rem;
          margin-left: auto;
        }
      }
    }
  }
`;

export function SessionsSection() {
  return <section css={SessionsSectionStyle}>
    <div className="container">
      <h2>
        소중한 경험을 나눠주실<br />
        발표자 분들을 소개합니다!
      </h2>
      <h3>발표 세션 소개</h3>
      <div style={{ overflow: 'hidden' }}>
        <ul className="session-list">
          {sessionDataFirstFloor.map(session => (
            <li className="session-list-item">
              <a href={`/sessions/${session.slug}`}>
                <h4 className="session-title">{session.title}</h4>
                <p className="session-speaker">{session.presenter.name} (<a href={`https://twitter.com/${session.presenter.socialMediaAccount}`} target="_blank">@{session.presenter.socialMediaAccount}</a>)</p>
                <img width="100" height="100" className="session-speaker-picture" src={speakerImages[session.slug]} />
              </a>
            </li>
          ))}
          {sessionDataSecondFloor.map(session => (
            <li className="session-list-item">
              <a href={`/sessions/${session.slug}`}>
                <h4 className="session-title">{session.title}</h4>
                <p className="session-speaker">{session.presenter.name} (<a href={`https://twitter.com/${session.presenter.socialMediaAccount}`} target="_blank">@{session.presenter.socialMediaAccount}</a>)</p>
                <img width="100" height="100" className="session-speaker-picture" src={speakerImages[session.slug]} />
              </a>
            </li>
          ))}

          <li className="sessions-notice">
            <h4>
              엑셀콘 발표 안내<br />
              자세히 보기
            </h4>
            <p>참여자는 최대 두 개의 세션을 들을 수 있으며, 티켓 구매 시의 1~3순위 신청에 따라 선착순으로 배정됩니다.</p>
            <a className="sessions-notice-button" href="/sessions">
              <img width="32" height="32" src={arrowRightCircle} alt="go" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>;
}