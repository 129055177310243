import React from "react"
import { css, jsx } from "@emotion/react"
import { GlobalNavigationBar } from "../../components/GlobalNavigationBar"
import { SessionsPageSessionsSection } from "../../sessionsPage/sections/SessionsPageSessionsSection";
import { SessionsPageShareSection } from "../../sessionsPage/sections/ShareSection";
import { SessionsPageViewTimetableSection } from "../../sessionsPage/sections/ViewTimetableSection";
import SiteHead from "../../head";
import { PageHero } from "../../components/PageHero";
import { RecoilRoot } from "recoil";

const PageStyle = css`
  padding-bottom: 10rem;
  background: #171717;
`;

export const Head = () => <SiteHead meta={{ title: "발표 안내 | EXCELCON" }} />

const SessionsPage = () => {
  return <RecoilRoot>
    <div css={PageStyle}>
      <GlobalNavigationBar />
      <PageHero>
        <h1>다양한 주제의 지식을<br /><span className="text-secondary">함께</span> 공유해요</h1>
      </PageHero>
      <SessionsPageSessionsSection />
      <SessionsPageViewTimetableSection />
      {/* <SessionsPageShareSection color="gray" /> */}
    </div>
  </RecoilRoot>
}

export default SessionsPage
