import React, { useEffect, useState } from "react"
import { css, jsx } from "@emotion/react"
import "../styles/index.css"
import { HeroSection, SessionsSection, IntroSection, MessageSection, LocationSection, NoticeSection } from "../indexPage/sections";
import { GlobalNavigationBar } from "../components/GlobalNavigationBar";
import SiteHead from "../head";
import { RecoilRoot } from "recoil";

const PageStyle = css`
  padding-bottom: 4rem;
`;

export const Head = () => <SiteHead />

const IndexPage = () => {

  return <RecoilRoot>
    <div css={PageStyle}>
      <GlobalNavigationBar />
      <HeroSection />
      <IntroSection />
      <SessionsSection />
      <MessageSection />
      <LocationSection />
      <NoticeSection />
      <div css={css`
      margin-top: 3rem;
      text-align: center;
      font-size: .75rem;
      color: #aaa;

      a {
        color: inherit;
      }
    `}>
        본 사이트는 <a href="https://infcon.day" target="_blank">인프콘 사이트</a>의 구성을 일부 차용하여 제작 되었습니다
      </div>
    </div>
  </RecoilRoot>
}

export default IndexPage
