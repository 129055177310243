import React, { useEffect, useState } from "react"
import { css } from "@emotion/react";
import { SectionStyle } from "../../styles/common";
import imgExcelconLogoBlack from "../../images/excelcon_logo_1-1.png"

const IntroSectionStyle = css`
  ${SectionStyle};
  background: #fff;
  color: #141414;

  .excelcon-logo {
    display: block;

    @media (max-width: 768px) {
      width: 200px;
    }
  }

  p {
    font-size: 1.25rem;
  }

  .subduded {
    color: #888888;
    font-size: 1rem;
  }
`;


export function IntroSection() {
  return <section css={IntroSectionStyle}>
    <div className="container">
      <img className="excelcon-logo" src={imgExcelconLogoBlack} width={275} alt="Excelcon"/>
      <h2>
        트위터 개발자의 성지,<br />
        <span className="text-primary">EXCEL PLACE</span>에서 열리는<br />
        네트워킹 파티
      </h2>
      <p>
        맛있는 음식과 술이 넘치는 공간<br />
        EXCEL PLACE에서 함께 모여요!
      </p>
      <p>
        다양한 주제의 발표를 함께 듣고<br />
        좋은 사람들과 멋진 시간을 보내세요.
      </p>
      <h3 className="text-primary">참가 신청 기간</h3>
      <p>9월 3일 토요일 정오 ~ 9월 6일 화요일 18시</p>
      <p className="subduded">
        참여 여부는 신청 후 랜덤으로 선정 되며,<br />
        참가비 30,000원이 발생합니다.
      </p>
    </div>
  </section>
}