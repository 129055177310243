import React from "react"
import { css, jsx } from "@emotion/react"
import { GlobalNavigationBar } from "../components/GlobalNavigationBar"
import SiteHead from "../head";
import { PageHero } from "../components/PageHero";
import { RecoilRoot } from "recoil";

const faqContent = [
  [
    "행사 중간에 참석해도 되나요?",
    "참석이 가능하지만 원활한 행사 진행을 위해서 가급적 행사 오픈 시간에 맞추어 참석하는 것을 권장 드려요."
  ],
  [
    "세션 발표는 무조건 하나 이상 들어야 하나요?",
    "세션 참석은 필수가 아니에요. 티켓 구매 시 세션 참석을 선택하지 않으시면 돼요."
  ],
  [
    "티켓을 타인에게 양도할 수 있나요?",
    "공식적으로 양도는 도와드리지 않아요. 다만 양도자의 개인 정보로 타인이 참석하는 경우를 따로 체크하고 제지하지는 않습니다. 이 경우 양도자가 원래 참여하는 세션과 구매한 티셔츠 사이즈 등의 정보를 변경해 드리지 못하는 점 양지해주세요."
  ],
  [
    "환불하고 싶어요. 어떻게 해야 하나요?",
    "티켓 판매 기간 동안은 festa.io 사이트에서 직접 취소가 가능해요. 이후 환불은 excelcon.day@gmail.com 메일로 [환불 요청] 제목을 달아 환불 요청을 해주세요. 단, 티셔츠를 함께 구매하신 경우 이미 주문이 된 뒤라 환불이 어려운 점 양해 부탁드려요."
  ],
  [
    "참석자 선정은 어떻게 이뤄지나요?",
    "초대권을 제외하고는 무작위 추첨으로 이루어져요."
  ],
  [
    "참석 확정 여부는 언제 공개되나요?",
    "9월 9일(금요일)에 공개되어요."
  ],
  [
    "전체 정원이 몇 명인가요?",
    "스태프와 초대권 참석자를 포함한 전체 인원 90명이에요."
  ],
  [
    "취소표가 발생하면 구매할 수 있는 기회가 있나요?",
    "취소표 구매 기간은 별도로 제공 되지 않아요."
  ]
]

const PageStyle = css`
  padding-bottom: 10rem;
  background: #171717;

  .faq-list {
    list-style: none;
    padding: 0;
    margin: 7.5rem 0 0 0;

    @media (max-width: 768px) {
      margin-top: 4rem;
    }

    li {
      margin-bottom: 1.5rem;
      font-size: 1.125rem;
      line-height: 25px;
      
      .question {
        padding: 1rem 2.5rem;
        border-radius: .5rem .5rem 0 0;
        background: white;
        color: black;
      }

      .answer {
        padding: 1rem 2.5rem;
        border-radius: 0 0 .5rem .5rem;
        background: rgba(255,255,255,.1);
        color: white;
      }

      @media (max-width: 768px) {
        .question, .answer {
          padding: 0.625rem 1rem;
          font-size: 1rem;
          line-height: 1.4;
        }
      }
    }
  }

  .section-question {
    margin-top: 7.5rem;
    text-align: center;

    h2 {
      font-size: 2rem;
    }

    a {
      font-size: 1.2rem;
      font-weight: 100;
      text-decoration: none;
      color: inherit;
    }

    @media (max-width: 768px) {
      h2 {
        font-size: 1.5rem;
      }

      a {
        font-size: 1rem;
      }
    }
  }
`;

export const Head = () => <SiteHead meta={{ title: "FAQ | EXCELCON" }} />

const FaqPage = () => {
  return <RecoilRoot>
    <div css={PageStyle}>
      <GlobalNavigationBar />
      <PageHero>
        <h1>
          FAQ<br />
          <span className="text-secondary">자주 묻는 질문</span>
        </h1>
      </PageHero>
      <div className="container">
        <ul className="faq-list">
          {faqContent.map(qna => (
            <li>
              <div className="question">
                Q: {qna[0]}
              </div>
              <div className="answer">
                A: {qna[1]}
              </div>
            </li>
          ))}
        </ul>
        <div className="section-question">
          <h2>기타 궁금한 사항 문의하기</h2>
          <a href="mailto:excelcon.day@gmail.com">excelcon.day@gmail.com</a>
        </div>
      </div>
    </div>
  </RecoilRoot>
}

export default FaqPage;
