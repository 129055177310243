import React from "react";
import { css, jsx } from "@emotion/react";
import { SectionStyle } from "../../styles/common";

import imgHoodieFront from "../../images/hoodie-front.jpg";
import imgHoodieBack from "../../images/hoodie-back.jpg";
import arrowRightInCircle from "../../images/arrow-right-in-circle.svg";


const HoodieSectionStyle = css`
  ${SectionStyle}
  padding: 120px 0;
  background-color: #1f1f1f;

  .hoodie-contents__title {
    margin: 0;

    font-size: 40px;
    line-height: 47.73px;
    font-weight: 700;
  }

  .hoodie-contents__title--below {
    margin-top: 240px;
  }

  .hoodie-contents__subtitle {
    margin: 50px 0 0;

    font-size: 24px;
    line-height: 29px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.8);
  }

  h3 {
    font-size: 2rem;
  }

  h4 {
    font-size: 1.5rem;
    font-weight: 400;
  }

  h3.purchase-info {
    margin-bottom: .5rem;
  }

  p.purchase-info {
    font-size: 1rem;
    margin-bottom: 2rem;

    @media (max-width: 768px) {
      font-size: .875rem;
    }
  }

  .link-purchase {
    img {
      width: 4rem;
      height: 4rem;

      @media (max-width: 768px) {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .hoodie-contents__photos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.25rem;

    img {
      display: block;
      width: 100%;
    }

    @media (max-width: 768px) {
      display: block;
    }
  }

  @media (max-width: 768px) {
    .hoodie-contents__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 29px;
      letter-spacing: -0.01em;
    }

    .hoodie-contents__title--below {
      margin-top: 90px;
    }

    .hoodie-contents__subtitle {
      margin: 20px 0 0;

      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: -0.01em;
    }

    h3 {
      font-size: 1.25rem;
    }

    h4 {
      font-size: 1rem;
    }
  }
`;


export const ContentsPageHoodieSection: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <section css={HoodieSectionStyle}>
      <div className="container hoodie-contents">
        <h2 className="hoodie-contents__title"><span className="text-secondary">후드집업</span> 예약 판매 안내</h2>
        <p className="hoodie-contents__subtitle">
          후드집업 예약 판매를 진행합니다. EXCELCON의 아이덴티티는 살리면서도 언제 어디든 잘 어울리게 활용할 수 있는 아이템이랍니다!
        </p>
        <p className="hoodie-contents__subtitle">
          *예약 판매로 준비된 수량이 모두 소진되는 경우, 현장 판매는 진행하지 않습니다.<br />
          *수익금은 전액 기부됩니다.
        </p>
        <h3>EXCELCON 후드집업</h3>
        <h4>size: L, XL, XXL</h4>
        <div className="hoodie-contents__photos">
          <img src={imgHoodieFront} alt="hoodie front" />
          <img src={imgHoodieBack} alt="hoodie back" />
        </div>
        <h3 className="text-center purchase-info">EXCELCON 후드집업 <span className="text-secondary">예약 구매하기</span></h3>
        <p className="text-center purchase-info">행사 시작 2시간 전인 9/17 16시에 판매를 마감합니다</p>
        <div className="text-center">
          <a className="link-purchase" href="https://festa.io/events/auYrxK6Dmov7kj2wt8Vb5V" target="_blank">
            <img width={68} height={64} src={arrowRightInCircle} alt="go" />
          </a>
        </div>
      </div>
    </section>
  );
}
